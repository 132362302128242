import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { DeleteTaskCommentPayload, DeleteTaskPayload, GetTasksPayload, PostTaskCommentPayload, PostTaskPayload, PutTaskCommentPayload, PutTaskPayload, SortOrder, useProjectApiStore, useTaskApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { Task, TaskComment, TaskList } from '@aedifion.io/aedifion-api'
import { formatDate } from '@/filters/formatting'
import { getFallbackName } from '@/utils/helpers/locale'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { RowData } from '@/components/SummarizedContent/types'
import { useAppStore } from '@/stores/app'

export type Keys = 'task' | 'componentinproject' | 'cost.saving.potential' | 'start' | 'assignee' | 'status' | 'action'
export type TasksSortingKeys = 'priority' | 'title' | 'componentinproject' | 'start' | 'assignee' | 'status'
export type TaskSortingPayload = {
  key: TasksSortingKeys
  order: SortOrder
}

export const useChecklistStore = defineStore('checklist', () => {
  const projectApiStore = useProjectApiStore()
  const taskApiStore = useTaskApiStore()
  const tasks = ref<TaskList|null>(null)
  const tasksSorting = ref<TaskSortingPayload>({ key: 'priority', order: SortOrder.Descending })
  const currentEditedTask = ref<Task|null>(null)
  const taskComments = ref<TaskComment[]|null>(null)
  const loading = ref(false)
  const loadingTask = ref(false)
  const loadingTaskComments = ref(false)

  const tasksTableRows = computed<RowData<Keys>[]>(() => {
    return (tasks.value as TaskList)?.items?.map((task: Task) => {
      return {
        assignee: {
          text: task.assignee ? `${task.assignee.firstName} ${task.assignee.lastName}` : 'N/A',
        },
        componentinproject: {
          text: task.componentinproject && task.componentinproject.length > 0 ? getFallbackName(task.componentinproject[0].nameDE, task.componentinproject[0].nameEN) : 'N/A',
        },
        'cost.saving.potential': {
          text: task.savings_potential?.value ?? 'N/A',
          unit: task.savings_potential?.unit ?? '',
        },
        data: {
          custom: task,
        },
        start: {
          text: task.start ? formatDate(task.start) : 'N/A',
        },
        status: {
          text: task.status,
        },
        task: {
          custom: {
            priority: task.priority,
          },
          text: task.title,
        },
        isGrayedOut: shouldTaskBeGrayedOut(task.status!),
      } as RowData<Keys>
    }) ?? []
  })

  const sortedTasksTableRows = computed<RowData<Keys>[]>(() => {
    return tasksTableRows.value.toSorted((a, b) => {
      if (a.isGrayedOut) {
        if (b.isGrayedOut) {
          return 0
        } else {
          return 1
        }
      } else if (b.isGrayedOut) {
        return -1
      }

      // The tasks are already sorted by the api
      return 0
    })
  })

  async function deleteTask (payload: DeleteTaskPayload) {
    loadingTask.value = true
    try {
      const taskIndex = tasks.value!.items!.findIndex((task) => task.id === payload.taskId)
      await taskApiStore.deleteTask(payload)
      if (taskIndex !== undefined && taskIndex !== -1) {
        tasks.value!.items!.splice(taskIndex, 1)
      }
      showSuccessNotification(i18n.global.t('notifications.success.delete', { resource: i18n.global.t('notifications.resources.task') }))
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.delete', { resource: i18n.global.t('notifications.resources.task') })}`)
      reportError(error)
    } finally {
      loadingTask.value = false
    }
  }

  async function getMoreTasks (): Promise<void> {
    const appStore = useAppStore()
    try {
      if (tasks.value === null) {
        const result: TaskList = await projectApiStore.getTasks({
          language: i18n.global.locale.value,
          page: 1,
          perPage: 20,
          projectId: appStore.projectId,
          sortBy: [tasksSorting.value.key],
          sortOrder: tasksSorting.value.order,
        })
        tasks.value = result
      } else {
        const result: TaskList = await projectApiStore.getTasks({
          language: i18n.global.locale.value,
          page: tasks.value.meta!.current_page + 1,
          perPage: 20,
          projectId: appStore.projectId,
          sortBy: [tasksSorting.value.key],
          sortOrder: tasksSorting.value.order,
        })
        tasks.value.meta = result.meta
        tasks.value.items!.push(...result.items!)
      }
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.tasks') })
      showErrorNotification(errorMessage)
      reportError(error)
    }
  }

  async function getTasks (payload: GetTasksPayload) {
    loading.value = true
    try {
      const result = await projectApiStore.getTasks(payload)
      tasks.value = result
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.tasks') })}`)
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  async function postTask (payload: PostTaskPayload) {
    loadingTask.value = true
    try {
      await taskApiStore.postTask({
        body: payload.body,
        projectId: payload.projectId,
      })
      showSuccessNotification(i18n.global.t('notifications.success.create', { resource: i18n.global.t('notifications.resources.task') }))
    } catch (error) {
      reportError(error)
      showErrorNotification(i18n.global.t('notifications.errors.create', { resource: i18n.global.t('notifications.resources.task') }))
    } finally {
      loadingTask.value = false
    }
  }

  async function putTask (payload: PutTaskPayload) {
    loadingTask.value = true
    try {
      const result = await taskApiStore.putTask(payload)
      const taskIndex = tasks.value?.items?.findIndex((task) => task.id === result.id)
      if (taskIndex !== undefined && taskIndex !== -1) {
        tasks.value!.items!.splice(taskIndex, 1, result)
      }
      if (currentEditedTask.value?.id === result.id) {
        currentEditedTask.value = result
      }
      showSuccessNotification(i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.task') }))
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.task') })}`)
      reportError(error)
    } finally {
      loadingTask.value = false
    }
  }

  async function getTaskComments (taskId: string) {
    clearTaskComments()
    loadingTaskComments.value = true

    try {
      const result = await taskApiStore.getTaskComments({ taskId })
      taskComments.value = result.items!
    } catch (error) {
      showErrorNotification(
        `${i18n.global.t('notifications.errors.fetch', {
          resource: i18n.global.t('notifications.resources.comments'),
        })}`,
      )
      reportError(error)
    } finally {
      loadingTaskComments.value = false
    }
  }

  async function putTaskComment (taskComment: PutTaskCommentPayload) {
    loadingTaskComments.value = true

    try {
      await taskApiStore.putTaskComment(taskComment)
      showSuccessNotification(
        i18n.global.t('notifications.success.update', {
          resource: i18n.global.t('notifications.resources.comment'),
        }),
      )
    } catch (error) {
      showErrorNotification(
        `${i18n.global.t('notifications.errors.update', {
          resource: i18n.global.t('notifications.resources.comment'),
        })}`,
      )
      reportError(error)
    } finally {
      loadingTaskComments.value = false
    }
  }

  async function postTaskComment (postTaskCommentPayload: PostTaskCommentPayload) {
    loadingTaskComments.value = true

    try {
      await taskApiStore.postTaskComment(postTaskCommentPayload)
      showSuccessNotification(
        i18n.global.t('notifications.success.create', {
          resource: i18n.global.t('notifications.resources.comment'),
        }),
      )
    } catch (error) {
      showErrorNotification(
        `${i18n.global.t('notifications.errors.create', {
          resource: i18n.global.t('notifications.resources.comment'),
        })}`,
      )
      reportError(error)
    } finally {
      loadingTaskComments.value = false
    }
  }

  async function deleteTaskComment (deleteTaskCommentPayload: DeleteTaskCommentPayload) {
    loadingTaskComments.value = true

    try {
      await taskApiStore.deleteTaskComment(deleteTaskCommentPayload)
      showSuccessNotification(
        i18n.global.t('notifications.success.delete', {
          resource: i18n.global.t('notifications.resources.comment'),
        }),
      )
    } catch (error) {
      showErrorNotification(
        `${i18n.global.t('notifications.errors.delete', {
          resource: i18n.global.t('notifications.resources.comment'),
        })}`,
      )
      reportError(error)
    } finally {
      loadingTaskComments.value = false
    }
  }

  function clearStore () {
    tasks.value = null
    clearCurrentEditedTask()
    clearTaskComments()
  }

  function clearTaskComments () {
    taskComments.value = null
  }

  function setCurrentEditedTask (task: Task) {
    currentEditedTask.value = task
  }

  function clearCurrentEditedTask () {
    currentEditedTask.value = null
  }

  function shouldTaskBeGrayedOut (taskStatus: Task.StatusEnum): boolean {
    return taskStatus === Task.StatusEnum.Done || taskStatus === Task.StatusEnum.Rejected || taskStatus === Task.StatusEnum.Failed
  }

  return {
    clearCurrentEditedTask,
    clearStore,
    clearTaskComments,
    currentEditedTask,
    deleteTask,
    deleteTaskComment,
    getMoreTasks,
    getTaskComments,
    getTasks,
    loading,
    loadingTask,
    loadingTaskComments,
    postTask,
    postTaskComment,
    putTask,
    putTaskComment,
    setCurrentEditedTask,
    sortedTasksTableRows,
    taskComments,
    tasks,
    tasksTableRows,
    tasksSorting,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChecklistStore, import.meta.hot))
}
